<template>
  <div
    id="spec-pie"
    ref="lineChart"
    style="flex: 1"
    :style="{ width, height }"
  ></div>
</template>
<script>
export default {
  name: "FlavorPieChart",
  props: {
    legend: {
      type: Object,
      default: () => {
        return {};
      },
    },
    series: {
      type: Object,
      default: () => {
        return {};
      },
    },
    width: String,
    height: String,
  },
  data() {
    return {
      pieChart: null,
    };
  },
  methods: {
    setData() {
      const pieChart = this.pieChart;
      pieChart.setOption({
        color: [
          "#91cc75",
          "#5470c6",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
        legend: Object.assign(
          {
            orient: "vertical",
            left: "0", // 将图例左侧居中
            top: "middle",
            align: "left",
            icon: "circle",
            itemWidth: 5, // 设置圆点宽度
            itemHeight: 5, // 设置圆点高度
            selectedMode: false,
          },
          this.legend
        ),

        series: [
          Object.assign(
            {
              type: "pie",
              radius: ["60%", "80%"],
              center: ["50%", "35%"], // 将饼图移到最右边
              avoidLabelOverlap: true,
              padAngle: 2,
              startAngle: 225, // 将起始角度设置为 270 度
              itemStyle: {
                borderRadius: 5,
                emphasis: {
                  shadowOffsetX: 0,
                  scale: true, // 设置为 true 时，强调的部分会放大
                },
              },
              label: {
                show: false,
                position: "center",
              },
              labelLine: {
                show: false,
              },

              emphasis: {
                // focus: 'self', // 或者设置为 'none' 或者数字（表示偏移的距离）
                // shadowBlur: 1,
                scale: true, // 设置为 true 时，强调的部分会放大
                scaleSize: 5, //加大部分加多多少
              },
              data: [],
            },
            this.series
          ),
        ],
      });

      this.highlightItem();
    },
    highlightItem() {
      if (this.series.data) {
        const values = this.series.data.map((item) => parseFloat(item.value));
        const max = Math.max(...values);
        const maxIndex = values.indexOf(max);

        // console.log(values)
        // console.log(max)
        // console.log(`index = ${maxIndex}`)
        if (maxIndex !== -1) {
          this.pieChart.dispatchAction({
            type: "highlight", //突出高亮显示;
            dataIndex: maxIndex,
          });
        }
      } else {
        return;
      }
    },
  },
  computed: {},
  watch: {
    width() {
      if (this.pieChart) {
        const options = this.pieChart.getOption();
        this.pieChart.setOption(options);
        this.highlightItem();
      }
      this.$nextTick(() => {
        this.pieChart.resize();
      });
    },
    series: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (this.pieChart) {
          const options = this.pieChart.getOption();
          options.series[0].data = newVal.data;
          options.legend[0].type = newVal.data.length >= 7 ? "scroll" : "";
          this.pieChart.setOption(options);
          this.highlightItem();
        }
      },
    },
  },
  mounted() {
    const pieChart = this.$echarts.init(this.$refs.lineChart);
    this.pieChart = pieChart;
    window.addEventListener("resize", this.setData);

    this.setData();
    this.highlightItem();
  },
  destroyed() {
    window.removeEventListener("resize", null, this.setData);
  },
};
</script>

<style scoped lang="less"></style>
